var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "50%",
        closable: true,
        visible: _vm.visible,
        title: "记录详情"
      },
      on: {
        close: function($event) {
          _vm.visible = false
        }
      }
    },
    [
      _c(
        "a-row",
        { attrs: { justify: "space-between", type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "分账记录ID" } },
                    [_vm._v(_vm._s(_vm.detailData.recordId))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "商户号" } }, [
                    _vm._v(_vm._s(_vm.detailData.mchNo))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "应用ID" } }, [
                    _vm._v(_vm._s(_vm.detailData.appId))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "支付接口代码" } },
                    [_vm._v(_vm._s(_vm.detailData.ifCode))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "系统支付订单号" } },
                    [_vm._v(_vm._s(_vm.detailData.payOrderId))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "支付订单渠道支付订单号" } },
                    [_vm._v(_vm._s(_vm.detailData.payOrderChannelOrderNo))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "订单金额" } }, [
                    _vm._v(_vm._s(_vm.detailData.payOrderAmount / 100))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "分账基数" } }, [
                    _vm._v(
                      _vm._s(_vm.detailData.payOrderDivisionAmount / 100) +
                        " （订单金额-手续费-退款金额）"
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "系统分账批次号" } },
                    [_vm._v(_vm._s(_vm.detailData.batchOrderId))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "上游分账批次号" } },
                    [_vm._v(_vm._s(_vm.detailData.channelBatchOrderId))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "状态" } },
                    [
                      _c(
                        "a-tag",
                        {
                          key: _vm.detailData.state,
                          attrs: {
                            color:
                              _vm.detailData.state === 0
                                ? "orange"
                                : _vm.detailData.state === 1
                                ? "blue"
                                : _vm.detailData.state === 2
                                ? "volcano"
                                : "volcano"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.detailData.state === 0
                                  ? "分账中"
                                  : _vm.detailData.state === 1
                                  ? "分账成功"
                                  : _vm.detailData.state === 2
                                  ? "分账失败"
                                  : "未知"
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "分账接收者ID" } },
                    [_vm._v(_vm._s(_vm.detailData.receiverId))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "收款账号组ID" } },
                    [_vm._v(_vm._s(_vm.detailData.receiverGroupId))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "收款账号别名" } },
                    [_vm._v(_vm._s(_vm.detailData.receiverAlias))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "分账接收账号类型" } },
                    [
                      _vm._v(
                        _vm._s(_vm.detailData.accType == 0 ? "个人" : "商户")
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "分账接收账号" } },
                    [_vm._v(_vm._s(_vm.detailData.accNo))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "分账接收账号名称" } },
                    [_vm._v(_vm._s(_vm.detailData.accName))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "分账关系类型" } },
                    [_vm._v(_vm._s(_vm.detailData.relationType))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "分账关系类型名称" } },
                    [_vm._v(_vm._s(_vm.detailData.relationTypeName))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c(
                    "a-descriptions-item",
                    { attrs: { label: "实际分账比例" } },
                    [
                      _vm._v(
                        _vm._s(
                          (_vm.detailData.divisionProfit * 100).toFixed(2)
                        ) + "%"
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "分账金额" } }, [
                    _vm._v(_vm._s(_vm.detailData.calDivisionAmount / 100))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "创建时间" } }, [
                    _vm._v(_vm._s(_vm.detailData.createdAt))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "a-descriptions",
                [
                  _c("a-descriptions-item", { attrs: { label: "更新时间" } }, [
                    _vm._v(_vm._s(_vm.detailData.updatedAt))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-row",
        { attrs: { justify: "start", type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { sm: 24 } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "上游返回数据包" } },
                [
                  _c("a-input", {
                    staticStyle: { height: "100px", color: "black" },
                    attrs: { type: "textarea", disabled: "disabled" },
                    model: {
                      value: _vm.detailData.channelRespResult,
                      callback: function($$v) {
                        _vm.$set(_vm.detailData, "channelRespResult", $$v)
                      },
                      expression: "detailData.channelRespResult"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }